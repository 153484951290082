<template>
  <b-row class="match-height">
    <b-col lg="8">
      <ListContest :data-list="contestList" />
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import getDataList from '@/firestore/data/getList'
import ListContest from '@/components/company/contest/ListContest.vue'

export default {
  components: {
    BRow,
    BCol,
    ListContest,
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters('jobApplicantContest', ['contestList']),
  },
  async mounted() {
    const data = {
      collection: 'contest',
    }
    const response = await getDataList(data)
    if (response.status === 'success') {
      this.$store.dispatch('jobApplicantContest/updateList', response.data)
    } else {
      window.console.log('error', response.msg)
    }
  },
}
</script>

<style lang="scss">
</style>
